
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { IRootState } from '../../store'
import { useTranslation } from 'react-i18next'
import { toggleSidebar } from '../../store/themeConfigSlice'
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconCaretsDown from '../icon/IconCaretsDown';
import IconMenuMailbox from '../icon/menu/IconMenuMailbox'
import IconMenuTodo from '../icon/menu/IconMenuTodo'
import IconMenuNotes from '../icon/menu/IconMenuNotes'
import IconMenuScrumboard from '../icon/menu/IconMenuScrumboard'
import IconMenuContacts from '../icon/menu/IconMenuContacts'
import IconMenuUsers from '../icon/IconMenuUsers'
import IconLockDots from '../icon/IconLockDots'
import IconUserPlus from '../icon/IconUserPlus'

const Sidebar = () => {
    const [currentMenu, setCurrentMenu] = useState<string>('')
    const [errorSubMenu, setErrorSubMenu] = useState(false)
    const themeConfig = useSelector((state: IRootState) => state.themeConfig)
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark)
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const toggleMenu = (value: string) => {
        setCurrentMenu((oldValue) => {
            return oldValue === value ? '' : value
        })
    }
    const role = localStorage.getItem("role") as "academy" | "school" | null

    
    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]')
        if (selector) {
            selector.classList.add('active')
            const ul: any = selector.closest('ul.sub-menu')
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link') || []
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click()
                    });
                }
            }
        }
    }, [])

    function handleLogout() {
        console.log("handleLogout")
        if (window.confirm("Are you sure you want to log out?")) {
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            window.location.reload()
        }
    }

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        
        <div className={semidark ? 'dark' : ''}>
            <nav className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${semidark ? 'text-white-dark' : ''}`} >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <NavLink to="/" className="main-logo flex items-center shrink-0">
                            <img className="w-8 ml-[5px] flex-none" src="/assets/images/koku.png" alt="logo" />
                            <span className="text-2xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light">NERS</span>
                        </NavLink>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90" />
                        </button>
                    </div>
                    <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
                        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    <li className="nav-item">
                                        <NavLink to={`/profile`} className="group">
                                            <div className="flex items-center">
                                                <IconMenuUsers className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">Profile</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/schedule" className="group">
                                            <div className="flex items-center">
                                                <IconMenuTodo className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">Attendance</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </PerfectScrollbar>
                    <div className="ltr:left-0 rtl:right-0 absolute bottom-0 p-4 w-full">
                        <button type="button" onClick={()=>handleLogout()} className="w-full flex p-2 justify-between items-center hover:bg-white-dark/10 rounded-md dark:hover:text-primary hover:text-primary dark:hover:bg-[#181F32] font-medium group" >
                            <div className="flex items-center">
                                <IconLockDots className="group-hover:!text-primary shrink-0" />
                                <div className="ltr:ml-3 rtl:mr-3">Logout</div>
                            </div>
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Sidebar