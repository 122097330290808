import { redirect } from "react-router-dom";

export const roleGuard = () => {
    if (localStorage.getItem("token") === null) return redirect("/login")
    return redirect("/")
    // if (localStorage.getItem("role") === "school") {
    //     return redirect("/school")
    // } else {
    //     return redirect("/academy")
    // }
}