import { PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from './store'
import store from './store'
import { toggleAnimation, toggleLayout, toggleLocale, toggleMenu, toggleNavbar, toggleRTL, toggleSemidark, toggleTheme } from './store/themeConfigSlice'
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'

function App({ children }: PropsWithChildren) {
    const themeConfig = useSelector((state: IRootState) => state.themeConfig)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(toggleTheme(localStorage.getItem('theme') || themeConfig.theme))
        dispatch(toggleMenu(localStorage.getItem('menu') || themeConfig.menu))
        dispatch(toggleLayout(localStorage.getItem('layout') || themeConfig.layout))
        dispatch(toggleRTL(localStorage.getItem('rtlClass') || themeConfig.rtlClass))
        dispatch(toggleAnimation(localStorage.getItem('animation') || themeConfig.animation))
        dispatch(toggleNavbar(localStorage.getItem('navbar') || themeConfig.navbar))
        dispatch(toggleLocale(localStorage.getItem('i18nextLng') || themeConfig.locale))
        dispatch(toggleSemidark(localStorage.getItem('semidark') || themeConfig.semidark))
    }, [dispatch, themeConfig.theme, themeConfig.menu, themeConfig.layout, themeConfig.rtlClass, themeConfig.animation, themeConfig.navbar, themeConfig.locale, themeConfig.semidark])


    return (
        <div className={`${(store.getState().themeConfig.sidebar && 'toggle-sidebar') || ''} ${themeConfig.menu} ${themeConfig.layout} ${ themeConfig.rtlClass } main-section antialiased relative font-nunito text-sm font-normal`} >
            <MantineProvider defaultColorScheme="light">{children}</MantineProvider>
        </div>
    )
}

export default App;
