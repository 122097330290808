import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { useState, Fragment, useEffect, useRef } from 'react';
import { publicHolidays, schoolHolidays } from '../../util/holidaysData'
import './style/CustomCalendar.css'
import { DataTable } from 'mantine-datatable'
import { NavLink } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react';
import IconX from '../../component/icon/IconX';
import IconCamera from '../../component/icon/IconCamera';
import Webcam from 'react-webcam';
import IconRefresh from '../../component/icon/IconRefresh';

type Props = {}

const PAGE_SIZES = [10, 20, 30, 50, 100]

const ScheduleView = (props: Props) => {
    const [items, setItems] = useState([
        {
            date: '13th Nov 2024',
            courses: [
                { course_id: 1, name: "Mathematics", branch_name: "KDU University", start_time: "08:30AM", end_time: "10:30AM" },
                { course_id: 2, name: "Biology", branch_name: "USM", start_time: "11:00AM", end_time: "12:30PM" },
                { course_id: 3, name: "Economics", branch_name: "INIT International College", start_time: "01:00PM", end_time: "02:30PM" }
            ]
        },
        {
            date: '12th Nov 2024',
            courses: [
                { course_id: 4, name: "Physics", branch_name: "Taylor's University", start_time: "09:00AM", end_time: "10:30AM" }
            ]
        },
        {
            date: '11th Nov 2024',
            courses: [
                { course_id: 5, name: "Chemistry", branch_name: "Monash University", start_time: "08:00AM", end_time: "10:00AM" },
                { course_id: 6, name: "English Literature", branch_name: "Sunway University", start_time: "10:30AM", end_time: "12:00PM" }
            ]
        },
        {
            date: '10th Nov 2024',
            courses: [
                { course_id: 7, name: "Mathematics", branch_name: "HELP University", start_time: "09:00AM", end_time: "11:00AM" },
                { course_id: 8, name: "Art", branch_name: "Segi College", start_time: "11:30AM", end_time: "01:00PM" },
                { course_id: 9, name: "Philosophy", branch_name: "INTI College", start_time: "02:00PM", end_time: "03:30PM" }
            ]
        },
        {
            date: '9th Nov 2024',
            courses: [
                { course_id: 10, name: "Computer Science", branch_name: "UTAR", start_time: "08:00AM", end_time: "10:00AM" },
                { course_id: 11, name: "Physics", branch_name: "Universiti Malaya", start_time: "10:30AM", end_time: "12:00PM" }
            ]
        },
        {
            date: '8th Nov 2024',
            courses: [
                { course_id: 12, name: "History", branch_name: "Universiti Putra Malaysia", start_time: "09:30AM", end_time: "11:30AM" },
                { course_id: 13, name: "Geography", branch_name: "Universiti Sains Malaysia", start_time: "12:00PM", end_time: "01:30PM" },
                { course_id: 14, name: "Statistics", branch_name: "KDU University", start_time: "02:00PM", end_time: "03:30PM" },
                { course_id: 15, name: "Physics Lab", branch_name: "Taylor's University", start_time: "04:00PM", end_time: "05:00PM" }
            ]
        },
        {
            date: '7th Nov 2024',
            courses: [
                { course_id: 16, name: "Biology", branch_name: "Sunway University", start_time: "08:00AM", end_time: "09:30AM" },
                { course_id: 17, name: "History of Art", branch_name: "Monash University", start_time: "10:00AM", end_time: "11:30AM" }
            ]
        },
        {
            date: '6th Nov 2024',
            courses: [
                { course_id: 18, name: "English", branch_name: "HELP University", start_time: "09:00AM", end_time: "10:30AM" },
                { course_id: 19, name: "Philosophy", branch_name: "Segi College", start_time: "11:00AM", end_time: "12:30PM" },
                { course_id: 20, name: "Mathematics", branch_name: "INTI College", start_time: "01:00PM", end_time: "03:00PM" }
            ]
        },
        {
            date: '5th Nov 2024',
            courses: [
                { course_id: 21, name: "Statistics", branch_name: "UTAR", start_time: "08:00AM", end_time: "09:30AM" }
            ]
        },
        {
            date: '4th Nov 2024',
            courses: [
                { course_id: 22, name: "Physics", branch_name: "Universiti Malaya", start_time: "09:00AM", end_time: "10:30AM" },
                { course_id: 23, name: "Biology", branch_name: "Universiti Putra Malaysia", start_time: "11:00AM", end_time: "12:30PM" },
                { course_id: 24, name: "Chemistry Lab", branch_name: "Taylor's University", start_time: "01:00PM", end_time: "03:00PM" },
                { course_id: 25, name: "Music", branch_name: "Sunway University", start_time: "03:30PM", end_time: "05:00PM" }
            ]
        }
    ])
    const [initialRecords, setInitialRecords] = useState(items)
    const [records, setRecords] = useState(initialRecords)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZES[0])

    useEffect(() => {
        setPage(1)
    }, [pageSize])

        
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        setRecords([...initialRecords.slice(from, to)])
    }, [page, pageSize, initialRecords])

    const [showCourseModal, setShowCourseModal] = useState(false)
    const [courses, setCourses] = useState([])
    const openCourse = (courses: any = null) => {
        setCourses(courses)
        setShowCourseModal(true)
    };

    const videoRef = useRef<any>(null);
    const [imageSrc, setImageSrc] = useState(null)
    const [showCamera, setShowCamera] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState({
        id: "",
        name:"",
    })

    const getCameraStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
        } catch (error) {
            console.error("Error accessing camera:", error);
        }
    };

        
    const captureImage = () => {
        const canvas = document.createElement("canvas") as any
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
        setImageSrc(canvas.toDataURL("image/jpeg"));
    };

    const [cameraFrontOrBack, setCameraFrontOrBack] = useState("environment")

    return (
        <div>
            <div className="flex items-center justify-between flex-wrap gap-4">
                <h2 className="text-xl">Schedule</h2>
            </div>

            <div className="mt-5 panel p-0 border-0 overflow-hidden">
                <div className="table-responsive">
                        <DataTable
                            className="whitespace-nowrap table-hover invoice-table"
                            records={records}
                            columns={[
                            {
                                accessor: 'date',
                                render: ({ date, courses }) => (
                                    <div onClick={() => openCourse(courses)}>
                                        <div className="text-black underline hover:no-underline font-semibold">{`${date}`}</div>
                                    </div>
                                ),
                            },
                            {
                                accessor: 'courses',
                                titleClassName:"No of Course",
                                render: ({ date, courses }) => (
                                    <div>
                                        <div className="text-black hover:no-underline font-semibold">{`${courses.length}`}</div>
                                    </div>
                                ),
                            },
                        ]}
                        totalRecords={initialRecords.length}
                        recordsPerPage={pageSize}
                        page={page}
                        key={"coach_id"}
                        onPageChange={(p) => setPage(p)}
                        recordsPerPageOptions={PAGE_SIZES}
                        onRecordsPerPageChange={setPageSize}
                        paginationText={({ from, to, totalRecords }) => `Showing  ${from} to ${to} of ${totalRecords} entries`}
                    />
                </div>
            </div>

            <Transition appear show={showCourseModal} as={Fragment} static >
                <Dialog as="div" open={showCourseModal} onClose={() => {
                    setShowCamera(false)
                    setShowCourseModal(false)
                }} className="relative z-[51]">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-[black]/60" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center px-4 py-8">
                            {showCamera ? (
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-5xl text-black dark:text-white-dark">
                                        <div className="flex">
                                            <Webcam audio={false} ref={videoRef} screenshotFormat="image/jpeg" width={"100%"} videoConstraints={{ facingMode: { exact: cameraFrontOrBack } }}/>
                                        </div>

                                        <div className="flex gap-8 my-4 items-center justify-center">
                                            <NavLink to={`/schedule/${selectedCourse.id}`} onClick={()=>{ 
                                                localStorage.setItem("courseName", selectedCourse.name)
                                                setShowCamera(false) 
                                            }} className="btn btn-sm btn-outline-primary">
                                                <IconCamera />
                                            </NavLink>
                                            <button onClick={()=>{
                                                if (cameraFrontOrBack == "user") {
                                                    setCameraFrontOrBack("environment")
                                                } else {
                                                    setCameraFrontOrBack("user")
                                                }
                                            }} className="btn btn-sm btn-outline-primary">
                                                <IconRefresh />
                                            </button>
                                            <div className="flex">
                                                <button type="button" onClick={() => {
                                                    setShowCamera(false)
                                                }} className="absolute top-4 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none" >
                                                    <IconX />
                                                </button>
                                            </div>
                                        </div>

                                    </Dialog.Panel>
                                </Transition.Child>
                            ) : (
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-5xl text-black dark:text-white-dark">
                                    <button
                                        type="button"
                                        onClick={() => setShowCourseModal(false)}
                                        className="absolute top-4 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none"
                                    >
                                        <IconX />
                                    </button>
                                    <div className="text-lg font-medium bg-[#fbfbfb] dark:bg-[#121c2c] ltr:pl-5 rtl:pr-5 py-3 ltr:pr-[50px] rtl:pl-[50px]">
                                        Courses
                                    </div>
                                    <div className="p-5 table-responsive">
                                        <table className="table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Branch</th>
                                                    <th>Time</th>
                                                    <th className="!text-center">Take Attendance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {courses.map((course:any, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div>{course.name}</div>
                                                        </td>
                                                        <td>{course.branch_name}</td>
                                                        <td>{course.start_time} - {course.end_time}</td>
                                                        <td>
                                                            <div className="flex gap-4 items-center justify-center">
                                                                <div  onClick={()=>{
                                                                    setSelectedCourse({
                                                                        name: course.name,
                                                                        id: course.id
                                                                    })
                                                                    getCameraStream()
                                                                    setShowCamera(true)
                                                                }} className="btn btn-sm btn-outline-primary">
                                                                    <IconCamera />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                        <div className="flex justify-end items-center mt-8">
                                            <button type="button" className="btn btn-outline-danger" onClick={() => setShowCourseModal(false)}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            )}
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default ScheduleView