import { useState, Fragment, useEffect } from 'react';
import { DataTable } from 'mantine-datatable'
import { NavLink, useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react';

type Props = {}

const PAGE_SIZES = [10, 20, 30, 50, 100]
const AttendanceView = (props: Props) => {
    const navigate = useNavigate()
    
    const [items, setItems] = useState([
        { student_id: 1, name: "Max", attended: true },
        { student_id: 2, name: "Sophia", attended: true },
        { student_id: 3, name: "Liam", attended: true },
        { student_id: 4, name: "Olivia", attended: true },
        { student_id: 5, name: "Ethan", attended: true },
        { student_id: 6, name: "Emma", attended: true },
        { student_id: 7, name: "Noah", attended: true },
        { student_id: 8, name: "Ava", attended: true }
    ])


    const handleCheckboxChange = (index:number) => {
        const updatedItems = items.map((item, i) => i === index ? { ...item, attended: !item.attended } : item )
        setItems(updatedItems)
    };

    return (
        <div>
            <div className="flex items-center justify-between flex-wrap gap-4">
                <h2 className="text-xl">{localStorage.getItem("courseName") || "Mathematics"} ({items.length})</h2>
            </div>
            <div className="my-5">
                <label htmlFor="address">Description</label>
                <textarea rows={3} placeholder="Enter Description" className="form-textarea resize-none min-h-[130px]" ></textarea>
            </div>
            <div className="mb-5 justify-self-end" onClick={()=>{navigate("/schedule")}}>
                <button type="button" className="btn btn-primary">Submit</button>
            </div>
            <div className="mt-5 panel p-0 border-0 overflow-hidden">
                <div className="table-responsive">
                    <table className="table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Attended</th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.map((item:any, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div>{item.name}</div>
                                    </td>
                                    <td>
                                        
                                        <div>
                                            <label className="inline-flex cursor-pointer">
                                                <input type="checkbox" checked={item.attended} onChange={()=>{handleCheckboxChange(index)}} className="form-checkbox" />
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default AttendanceView