import axios from 'axios'
import React, { useState } from 'react'
import IconUser from '../../component/icon/IconUser'
import IconMail from '../../component/icon/IconMail'
import IconLockDots from '../../component/icon/IconLockDots'
import { Link, useNavigate } from 'react-router-dom'
import Preloader from '../../component/preloader/Preloader'

type Props = {}

const ResetPassword = (props: Props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [otp, setOtp] = useState("")
    const [loading, setLoading] = useState(false)

    const generateOtp = async () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/generate-otp`, {
            "email": email,
            "reset_password": true,
        }).then( res => {
            setLoading(false)
            console.log("res", res)
            if (res.status == 200) {
                setShowResetPassword(true)
            }

        }).catch( err => {
            setLoading(false)
            console.log("err", err.response)
            alert(err.response.data ? err.response.data.error : "Error generating OTP")
        })
    }

    const resetPassword = async () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/reset-password`, {
            "email": email,
            "otp": otp,
            "new_password": password,
            "confirm_new_password": confirmPassword,
        }).then( res => {
            setLoading(false)
            console.log("res", res)
            alert("Password reset succesfully")
            navigate("/login")

        }).catch( err => {
            setLoading(false)
            console.log("err", err.response)
            alert(err.response.data ? err.response.data.error : "Error Resetting")
        })
    }

    return (
        <div>
            <div className="absolute inset-0">
                <img src="/assets/images/auth/bg-gradient.png" alt="image" className="h-full w-full object-cover" />
            </div>
            <div className="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
                    <div className="relative flex flex-col justify-center rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
                        <div className="mx-auto w-full max-w-[440px]">
                            <div className="mb-10">
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">Forgot Password</h1>
                                <p className="text-base font-bold leading-normal text-white-dark">Reset your password</p>
                            </div>
                            <form className="space-y-5 dark:text-white">
                                <div>
                                    <label className="text-white-dark">Email</label>
                                    <div className="relative text-white-dark">
                                        <input disabled={showResetPassword} type="email" placeholder="Enter Email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                {showResetPassword && (
                                <>
                                <div>
                                    <label className="text-white-dark">OTP</label>
                                    <div className="relative text-white-dark">
                                        <input type="text" placeholder="Enter OTP" value={otp} onChange={(e)=>setOtp(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label className="text-white-dark">Password</label>
                                    <div className="relative text-white-dark">
                                        <input type="password" placeholder="Enter Password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label className="text-white-dark">Confirm Password</label>
                                    <div className="relative text-white-dark">
                                        <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>
                                </>
                                )}
                                <button type="button" className="btn btn-gradient !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]" onClick={()=> {
                                    if (showResetPassword) { 
                                        resetPassword()
                                    } else {
                                        generateOtp()
                                    }
                                }}>
                                    {loading ? (
                                        <span className="animate-spin border-2 border-black dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"></span>
                                    ) : (
                                        <span>{showResetPassword ? "RESET" : "SEND OTP"}</span>
                                    )}
                                </button>
                            </form>
                            <div className="relative my-7 text-center md:mb-9">
                                <span className="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark"></span>
                                <span className="relative bg-white px-2 font-bold uppercase text-white-dark dark:bg-dark dark:text-white-light">or</span>
                            </div>
                            <div className="text-center text-white-dark dark:text-white">
                                Already have an account ?&nbsp;
                                <Link to="/login" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    SIGN IN
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword