import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

type Props = {}

const ProfileView = (props: Props) => {
    const navigate = useNavigate()
    const [coachData, setCoachData] = useState({
        fullName:"",
        email: "",
        phoneNumber: "",
        city: "",
        dob: "",
        verified: false,
        ic: ""
    })

    useEffect(() => {
        getCoachDetail()
    }, [])
    
    const getCoachDetail = async () => {
        const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : {}
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/coach/${userStorage.coach_id}`, {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Content-Type": "application/json"
            }
        }).then( res => {
            console.log("res", res.data.data)
            setCoachData({
                fullName: res.data.data.name,
                email: res.data.data.email,
                city: res.data.data.city,
                phoneNumber: res.data.data.phone,
                dob: res.data.data.birth_date,
                verified: res.data.data.is_approved,
                ic: res.data.data.ic_number
            })
            

        }).catch( err => {
            console.log("err", err)
            alert("Server Error")
        })
    }
    
    const updateCoach = async () => {
        const userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : {}
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/coach/edit/${userStorage.coach_id}`, {
            "name": coachData.fullName,
            "email": coachData.email,
            "city": coachData.city,
            "phone": coachData.phoneNumber,
            "birth_date": coachData.dob,
            "ic_number": coachData.ic,
        } ,{
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Content-Type": "application/json"
            }
        }).then( res => {
            console.log("res", res.data)
            alert("Updated Succesfully")

        }).catch( err => {
            console.log("err", err)
            alert("Server Error")
        })
    }
    return (
        <div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <span>Coach Settings</span>
                </li>
            </ul>
            <div className="pt-5">
                <form className="border border-[#ebedf2] dark:border-[#191e3a] rounded-md p-4 mb-5 bg-white dark:bg-black">
                    <div className="flex flex-col sm:flex-row">
                        <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full sm:w-2/12 mb-5">
                            <img src="/assets/dummy/profile-31.jpeg" alt="img" className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mx-auto" />
                        </div>
                        <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 gap-5">
                            <div>
                                <label htmlFor="name">Full Name</label>
                                <input id="name" value={coachData.fullName} onChange={(e)=>setCoachData( prev => ({ ...prev, fullName: e.target.value}))} type="text" placeholder="Jimmy Turner" className="form-input" />
                            </div>
                            <div>
                                <label htmlFor="email">Email</label>
                                <input id="email" value={coachData.email} onChange={(e)=>setCoachData( prev => ({ ...prev, email: e.target.value}))} type="email" placeholder="Jimmy@gmail.com" className="form-input" />
                            </div>
                            <div>
                                <label htmlFor="ic">Identification Number</label>
                                <input id="ic" value={coachData.ic} onChange={(e)=>setCoachData( prev => ({ ...prev, ic: e.target.value}))} type="text" placeholder="12345678" className="form-input" />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone</label>
                                <input id="phone" value={coachData.phoneNumber} onChange={(e)=>setCoachData( prev => ({ ...prev, phoneNumber: e.target.value}))} type="number" placeholder="+1 (530) 555-12121" className="form-input" />
                            </div>
                            <div>
                                <label htmlFor="address">City</label>
                                <input id="address" value={coachData.city} onChange={(e)=>setCoachData( prev => ({ ...prev, city: e.target.value}))} type="text" placeholder="New York" className="form-input" />
                            </div>
                            <div>
                                <label htmlFor="location">Date of Birth</label>
                                <input id="location" value={coachData.dob} onChange={(e)=>setCoachData( prev => ({ ...prev, dob: e.target.value}))} type="text" placeholder="Location" className="form-input" />
                            </div>
                            <div></div>
                            <div>
                                <label className="inline-flex cursor-pointer">
                                    <input disabled type="checkbox" checked={coachData.verified} className="form-checkbox" />
                                    <span className="text-white-dark relative checked:bg-none">Verified</span>
                                </label>
                            </div>
                            <div className="sm:col-span-2 flex mt-3 gap-2">
                                <button type="button" onClick={()=>{navigate("/")}} className="btn btn-secondary">
                                    Back
                                </button>
                                <button type="button" onClick={()=>{updateCoach()}} className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProfileView