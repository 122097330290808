const Index = () => {

    return (
        <div>
            <h1>starter page</h1>
        </div>
    );
};

export default Index
